import './App.css';
import About from './components/About';
import Kontakt from './components/Kontakt';
import LandingPage from './components/LandingPage';
import Projekt from './components/Projekt';
import Publikationer from './components/Publikationer';
import Samarbetspartners from './components/Samarbetspartners';
import {useState, useEffect} from 'react'


function App() {

  // const menuItems = document.querySelector('.menu-items')
  // const menuBars = document.querySelector('.menu-bars')
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isBarShow, setIsBarShow] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  

  const handleBarClick = () => {
 setIsMenuShow(true)
 setIsBarShow(false)
  }

  const handleOutsideMenuClick = (e) => {
    const menuItems = document.querySelector('.menu-items')
    if (isMenuShow===true && e.currentTarget !== menuItems) {
      setIsMenuShow(false)
      setIsBarShow(true)
    }
  }

 useEffect(() => {
   window.addEventListener('scroll', ()=> {
     if (window.scrollY > 300)
      setIsScrolled(true)
     else
     setIsScrolled(false) 
   })
 }, [])


  return (
    <div onClick={handleOutsideMenuClick}>
      <i onClick={handleBarClick} className={`fas fa-2x fa-bars menu-bars ${isBarShow ? '' : 'visually-hidden'}`}></i>
      <div className= {`menu-items ${ isMenuShow ? '' : 'visually-hidden'}`}>
        <h6 onClick= {()=> document.querySelector('#about').scrollIntoView()}>Om Västerholmen Konsult</h6>
        <h6 onClick= {()=> document.querySelector('#publikationer').scrollIntoView()}>Publikationer</h6>
        <h6 onClick= {()=> document.querySelector('#projekt').scrollIntoView()}>Projekt</h6>
        <h6 onClick= {()=> document.querySelector('#samarbetspartners').scrollIntoView()}>Samarbetspartners</h6>
        <h6 onClick= {()=> document.querySelector('#kontakt').scrollIntoView()}>Kontakt</h6>
      </div>
      { isScrolled && <i onClick={()=> document.querySelector('#landing-page').scrollIntoView()} className="fas fa-arrow-up fa-2x"></i>}
      <div id="landing-page"><LandingPage/></div>
      <div  id="about">< About/></div>
      <div  id="publikationer"><Publikationer /></div>
      <div  id="projekt"><Projekt /></div>
      <div  id="samarbetspartners"><Samarbetspartners /></div>
      <div  id="kontakt"><Kontakt/></div>
      
    </div>
   


  );
}

export default App;
