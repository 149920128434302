import React from 'react'
import kpmg from '../assets/kpmg.jpg'
import tm from '../assets/TM.jpg'
import wf from '../assets/WFAdvisory.png'
import './samarbetspartners.css'

const Samarbetspartners = () => {

    // const handleCardClick = (e) => {
    //     e.currentTarget.classList.toggle('samarbetspartners-card-height')
    //     e.currentTarget.classList.toggle('show-info')
    // }



    return (
        <div className="samarbetspartners-wrapper p-5">
            <h4 className="text-center samarbetspartners-header mb-2">Västerholmen Konsults samarbetspartners:</h4>
            <div className="container-md d-flex flex-column justify-content-evenly align-items-center px-md-4">
                <div className="samarbetspartners card my-4 ">
                    <img
                        id="wf-logo"
                        src={wf}
                        alt="W&F Advisory logo"
                    
                    />
                    <p className="text-center samarbetspartners-item-text">W&F med Lars Wennberg och Västerholmen Konsult har tagit fram Svensk kod för styrning av kooperativa och ömsesidiga företag. Vi anordnar regelbundet Kooperativa
                        Summits för samarbetspartners och externa gäster.</p>
                    <a href="http://www.wfadvisory.se/" target="_blank" rel="noreferrer">Till webbplats</a>
                </div>
                <div className="samarbetspartners card my-4 ">
                    <img
                        id="kpmg-logo"
                        src={kpmg}
                        alt="KPMG logo"
                    
                    />
                    <p className="text-center samarbetspartners-item-text">KPMG är bland annat specialiserade på rådgivningstjänster till kooperativ sektor inom skatt,
                        digitalisering, föreningsstyrning, finans och företagstransaktioner samt revision.</p>
                    <a href="http://home.kpmg/se/sv/home/industries/kooperativ.html" target="_blank" rel="noreferrer">Till webbplats</a>
                </div>
                <div className="samarbetspartners card my-4 ">
                    <img
                        id="tm-logo"
                        src={tm}
                        alt="TM & partners logo"
                        className="mt-3"
                    />
                    <p className="text-center mt-3 samarbetspartners-item-text">TM är bland annat specialiserade på juridiska tjänster till kooperativ sektor inom
                        föreningsrätt, fusioner och företagstransaktioner.</p>
                    <a href="http://tmpartners.se/branscher/kooperativa-verksamheter/" target="_blank" rel="noreferrer">Till webbplats</a>
                </div>

            </div>
        </div >
    )
}

export default Samarbetspartners
