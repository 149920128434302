import React from 'react'
import boImg from '../assets/Bo.png'
import './about.css'

const About = () => {
    return (

        <main className="container px-5">

            <div className="px-md-4">
                
                    


                    <p className="text-center text-md-start about-text">Västerholmens Konsult bildades 2014 efter att jag hade arbetat 35 år i
                        olika
                        kooperativa företag och även några år som advokat på advokatfirman Vinge. Namnet på företaget kommer
                        från en liten ö i Stockholms skärgård. Ett av mina favoritmål den årstid när båten kan ge
                        avkoppling.
                    </p>
                    <div className="d-flex justify-content-center align-items-center py-3 float-md-end">
                        <img src={boImg}
                            alt="Bo Thorstorp, ägare av Västerholmen Konsult"
                            width="200"
                            height="250"
                            />
                            
                    </div>
                    <p className="text-center text-md-start about-text"> Under karriären har jag hunnit med att vara expert i 2010 års
                        föreningslagsutredning och arbetat med
                        utformning av 2018 års lagstiftning. Arbetet som medförfattare av Norstedts kommentar till lagen har
                        gett
                        mig en djup förståelse om ekonomiska föreningar och lagstiftningen runt dem. Dessutom brinner jag
                        för
                        att utveckla den kooperativa affärsmodellen.
                    </p>

                    <p className="text-center text-md-start mb-5 about-text">
                        Ekonomisk förening är en fantastiskt flexibel associationsform som ligger helt rätt i tiden. Den
                        passar
                        både för multinationella företag med tusentals medlemmar och små företag med bara tre. Det är ett
                        demokratiskt och mångsidigt sätt att tillsammans med andra driva företag. Det passar i en
                        föränderlig
                        värld där delägare/medlemmar kan komma och gå på ett sätt som inte är möjligt i mer traditionella
                        företagsformer.
                    </p>
               
            </div>

        </main>





    )
}

export default About
