import React from 'react'
import './kontakt.css'

const Kontakt = () => {
    return (
        <div className="kontakt-wrapper py-5">
            <h3 className="d-block text-center display-3 kontakt mb-4 rubrik">Kontakt</h3>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="kontakt info"><i className="kontakt ikon fas fa-envelope p-2"></i><a className="mail-adress" href="mailto:bo@thorstorp.com">bo@thorstorp.com</a> </span>
                <span className="kontakt info"><i className=" kontakt ikon fas fa-phone-alt p-2"></i>+46 70 291 44 20</span>
                <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
                    <span className="text-center kontakt info"><strong>Postadress:</strong></span>

                    <span className="kontakt info">Västerholmen Konsult, c/o Thorstorp</span>

                    <span className="kontakt info">Torstenssonsgatan 7, lgh 1101</span>

                    <span className="kontakt info">114 56, Stockholm</span>
                </div>
                <div className="my-3 d-flex flex-column justify-content-center align-items-center">
                    <span className="kontakt info"><strong>Besöksadresser:</strong></span>

                    <span className="kontakt info">Stockholm: Torstenssonsgatan 7</span>

                    <span className="kontakt info">Barcelona: Carrer Sant Pere Més Alt 48</span>
                </div>
            </div>
            <small id="credit-text">Foto på startsidan taget av Lukas Menzel.<br /> 
            <a href="https://unsplash.com/@lukasmenzel" target="_blank" rel="noreferrer">Fler bilder tagna av Lukas.</a></small>
        </div>
    )
}

export default Kontakt
