import React from 'react'
import './landingpage.css'

const LandingPage = () => {
    return (
        <div className="mainSection">
            <header className="header pt-3">
                <h1 className="display-1 text-center">Västerholmen Konsult</h1>
            </header>
            <h2 className="display-6 text-center">
                Specialist inom föreningsrätt.
            </h2>
         


        </div>
    )
}

export default LandingPage
