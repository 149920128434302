import React from 'react'
import loef from '../assets/LagenOmEkonomiskaFöreningar.png'
import svenskkod from '../assets/SvenskKod.png'
import vagledning from '../assets/Vägledning.png'
import invest from '../assets/InvesterandeMedlemmar.png'
import './publikationer.css'

const Publikationer = () => {
    return (
        <div className="px-5 publikationer-wrapper py-5">

            <h2 className="text-center mb-5">Publikationer</h2>
            <div className="row justify-content-center px-xl-5">
                <div className="publikationer card mb-4 mx-md-3">
                    <h5>Lagen om ekonomiska föreningar</h5>
                    <a href="http://www.bokus.com/bok/9789139021018/lagen-om-ekonomiska-foreningar-en-kommentar/"
                        target="_blank" rel="noreferrer">
                        <img
                            id="lagen"
                            src={loef}
                            alt="Omslaget till Lagen Om Ekonomiska Föreningar"
                        />
                        <label className="cover-label" htmlFor="lagen">Mer information</label>
                    </a>
                    <p className="co-author-text"> Medförfattare: Sten Andersson och Anders Mallmén</p>
                </div>
                <div className="publikationer card mb-4 mx-md-3">
                    <h5>Svensk kod för styrning av kooperativa och ömsesidiga företag</h5>
                    <a className="" href="http://svenskkooperation.se/kod-kooperativa-omsesidiga-foretag/" target="_blank" rel="noreferrer"><img
                        id="koden" src={svenskkod}
                        alt="Omslaget till Svensk kod för styrning av kooperativa och ömsesidiga företag"
                        width="200"
                        height="250"
                    />
                        <label className="cover-label" htmlFor="koden">Mer information</label>
                    </a>
                    <p className="co-author-text">Medförfattare: Lars Wennberg, W&F Advisory</p>

                </div>
                <div className="publikationer card mb-4 mx-md-3">
                    <h5 className="">Vägledning för rollen som granskare ur ett medlemsperspektiv</h5>
                    <a href="http://svenskkooperation.se/va%CC%88gledning-om-rollen-som-granskare-ur-ett-medlemsperspektiv/"
                        target="_blank" rel="noreferrer"><img
                            id="vagledning"
                            src={vagledning}
                            alt="Omslaget till Vägledning för rollen som granskare ur ett medlemsperspektiv"
                            width="200"
                            height="250"
                        />
                        <label className="cover-label" htmlFor="vagledning">Mer information</label>
                    </a>
                    <p className="co-author-text">Medförfattare: Lars Wennberg, W&F Advisory</p>
                </div>
                <div className="publikationer card mb-4 mx-md-3">
                    <h5>Investerande medlemmar</h5>
                    <a className=""
                        href="https://svenskkooperation.se/wp-content/uploads/2018/12/investerande-medlemmar-svensk-kooperation-uppdaterad-version-2019.pdf"
                        target="_blank" rel="noreferrer">
                        <img
                            id="invest"
                            src={invest}
                            alt="Omslaget till Investerande medlemmar"
                            width="200"
                            height="250"
                        />
                        <label className="cover-label" htmlFor="invest">Mer information</label>
                    </a>
                    <p className="co-author-text">Medförfattare: Peter Nilsson, KPMG</p>
                </div>
            </div>
        </div>
    )
}

export default Publikationer
