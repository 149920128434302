import React from 'react'
import norraSkogLogo from '../assets/NorraSkog.png'
import biometriaLogo from '../assets/BiometriaLogo.png'
import './projekt.css'

const Projekt = () => {
    return (
        <div className="container px-5 py-5">
            <div className="d-flex flex-column justify-content-evenly align-items-center px-2">
                <h4 className="mb-4 text-center projekt-header">Västerholmen Konsult är rådgivare i föreningsrättsliga frågor till de stora företagen inom både producent och
                    konsumentkooperationen. <br/><br /> Västerholmen Konsult har medverkat som rådgivare vid:</h4>
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="d-flex flex-column justify-content-between justify-content-md-evenly align-items-center projekt-item">
                                <div className="mb-3">
                                    <p className="text-center projekt-text">Bildandet av Norra Skog genom fusion mellan Norra skogsägarna och Skogsägarna Norrskog. En affär med 28,000 medlemmar och 4 miljarder i
                                        omsättning.</p>
                                </div>
                                <div >
                                    <img
                                        className="d-block"
                                        src={norraSkogLogo}
                                        alt="NorraSkog logo"
                                        width="200"
                                        height="100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="d-flex flex-column justify-content-between justify-content-md-evenly align-items-center projekt-item">
                                <div className="mb-3">
                                    <p className="text-center projekt-text">Bildandet av Biometria ekonomisk förening genom fusion mellan Sveriges virkesmätningsföreningar.
                                        Sammanslagningen innebar att en riksomtäckande organisation för mätning och redovisning av virke
                                        bildades med 800 medlemmar och 600 miljoner i omsättning.</p>
                                </div>
                                <div >
                                    <img
                                        className="d-block"
                                        src={biometriaLogo}
                                        alt="Biometria logo"
                                        width="200"
                                        height="50"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projekt
